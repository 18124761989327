import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import Form from "react-bootstrap/Form"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import CardDeck from "react-bootstrap/CardDeck"
import Container from "react-bootstrap/Container"

import { HiOutlinePhone } from "react-icons/hi"

import Header from "../components/Layout/header"
import Footer from "../components/Layout/footer"
import CallMeBack from "../components/callback"
import CardMember from "../components/CardMember"
import SEO from "../components/Global/MetaHead"

const Kontakty = () => {
  const data = useStaticQuery(graphql`
    query {
      ogImage: contentfulAsset(
        file: { fileName: { eq: "center-licenziy-opengraph-min.png" } }
      ) {
        title
        id
        fixed(width: 620, height: 460) {
          src
        }
      }
      staff: allContentfulManager(
        filter: { node_locale: { eq: "uk" }, isActive: { eq: true } }
      ) {
        nodes {
          id
          name
          phone
          viber
          description
          binotelClass
          photo {
            fixed(width: 100, height: 100) {
              ...GatsbyContentfulFixed_withWebp
            }
            fluid(maxWidth: 300, quality: 100) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
      image: contentfulAsset(file: { fileName: { eq: "call.jpeg" } }) {
        fluid(maxWidth: 1920) {
          ...GatsbyContentfulFluid_withWebp
        }
        title
        id
      }
    }
  `)

  const GradientBG = [
    data.image.fluid,
    `linear-gradient(
      25deg, 
      rgba(128,0,128,0.2),
      rgba(35, 65, 97,1) 80%)`,
  ].reverse()

  const pageSlug = "kontakty"

  return (
    <>
      <SEO
        title="Контакти Центру Державних Ліцензій України"
        description="Єдине вікно: Центр Ліцензій. Контакти, адреса, графік роботи, як знайти."
        image={`https:${data.ogImage.fixed.src}`}
        url="/kontakty/"
        lang="uk"
      />
      <Header />
      <BackgroundImage
        fluid={GradientBG}
        className="jumbotron jumbotron-fluid mb-5 contacts-bg"
      >
        <div className="container text-center">
          <h1 className="pb-md-5 pb-sm-3 w-100">Контакти Центру Ліцензій</h1>
          <p className="lead pb-3">
            Центр Ліцензій — всеукраїнський онлайн-сервіс державних дозвільних
            документів
          </p>
          <a
            className="btn btn-danger ml-md-3 mt-md-0 mt-3 btn-lg binct-phone-number-1"
            href="tel:+380683328808"
          >
            <HiOutlinePhone />{" "}
            <span className="binct-phone-number-1" id="contactPhone1">
              (068) 332-88-08
            </span>
          </a>
          <a
            className="btn btn-danger ml-md-3 mt-md-0 mt-3 btn-lg binct-phone-number-2"
            href="tel:+380994440124"
          >
            <HiOutlinePhone />{" "}
            <span className="binct-phone-number-2" id="contactPhone2">
              (099) 444-01-24
            </span>
          </a>
          <p></p>
        </div>
      </BackgroundImage>
      <section className="kontakty container mb-5">
        <div className="row">
          <div className="col-md-8 col-sm-12">
            <p>
              Працюємо однаково швидко в будь-якому регіоні України, надаємо
              професійну консультацію.
            </p>
            <h2 className="">Напішіть нам</h2>
            <Form
              data-netlify="true"
              netlify-honeypot="bot-field"
              action="/dyakuemo/"
              method="post"
              name="contactForm"
              form-name="contactPageForm"
              id="contactForm"
              // onSubmit="submit"
            >
              <Form.Group hidden>
                <input type="hidden" name="form-name" value="contactForm" />
                <Form.Label>
                  Поле для незаполнения{" "}
                  <Form.Control type="text" name="bot-field" />
                </Form.Label>
              </Form.Group>
              <Form.Group>
                <Form.Label>Ваше повідомлення</Form.Label>
                <Form.Control
                  required
                  as="textarea"
                  rows={3}
                  name="contactMessage"
                />
              </Form.Group>
              <Form.Row>
                <Col>
                  <Form.Control
                    required
                    name="contactPhone"
                    placeholder="Ваш номер телефону*"
                  />
                </Col>
                <Col>
                  <Form.Control placeholder="Ваше ім'я" name="contactName" />
                </Col>
              </Form.Row>
              <Form.Group>
                <Form.Text className="text-muted">
                  Відповідаємо дуже швидко з 10:00 по 18:00.
                  <br /> Заявки онлайн приймаються цілодобово!
                </Form.Text>
              </Form.Group>
              <Button
                variant="warning"
                type="submit"
                className=""
                block
                size="lg"
              >
                Надіслати повідомлення
              </Button>
            </Form>
          </div>
          <div className="col-md-4 col-sm-12">
            <p className="mt-3 text-uppercase">Графік роботи колл-центру</p>
            <ul className="list-unstyled">
              <li>Понеділок — з 10:00 по 18:00</li>
              <li>Вівторок — з 10:00 по 18:00</li>
              <li>Середа — з 10:00 по 18:00</li>
              <li>Четвер — з 10:00 по 18:00</li>
              <li>П'ятниця — з 10:00 по 18:00</li>
              <li>Субота — з 10:00 по 16:00</li>
              <li>Неділя — вихідний</li>
            </ul>
            <p>
              Звернення на сайті приймаються <strong>цілодобово</strong>.
            </p>
            <p>
              ТОВ «ЦЕНТР ЛІЦЕНЗІЙ»
              <br />
              03164, Україна,
              <br />
              Київ, вул. Підлісна, 1, оф. 8
            </p>
            <p>licenzii.ukr@gmail.com</p>
          </div>
        </div>
      </section>
      <Container as="section">
        <div className="title2 py-5 text-uppercase h3">Наша команда</div>
        <CardDeck className="managers text-white justify-content-center">
          {data.staff.nodes.map(manager => {
            return (
              <CardMember
                key={manager.id}
                fluid={manager.photo.fluid}
                name={manager.name}
                description={manager.description}
                phone={manager.phone}
                viber={manager.viber}
                lang="uk"
                binotelClass={manager.binotelClass}
              />
            )
          })}
        </CardDeck>
      </Container>
      <CallMeBack />
      <Footer slug={`/${pageSlug}/`} />
    </>
  )
}

export default Kontakty
